import type { Either } from "~/lib/deps";
import type { ApiError } from "~/lib/services/apiError";

export function handleError(e: Error) {
  console.error(e);
  return undefined;
}

export function handleEither<T>(e: Either<ApiError, T>) {
  if (e.isLeft()) {
    console.error("Error inesperado:", e.value);
    return undefined;
  }

  return e.value;
}
