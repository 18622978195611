interface ApiError {
  message: string;
}

export class ApiClientError implements ApiError {
  message: string;

  constructor(message?: string) {
    this.message = message || "Erro inesperado.! Tente novamente mais tarde.";
  }
}

export type { ApiError };
